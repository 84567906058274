export module Menu {
    declare const $: any;
    const KEYCODE_ENTER = 13;
    const KEYCODE_ESC = 27;
    const COMPACTMENU_MEDIA_QUERY = "(max-width: 991px)";
    const MENU_OPEN_DELAY = 300;
    const MENU_CLOSE_DELAY = 900;

    let isCompact = false;
    let openTimer: NodeJS.Timeout | null = null;
    let closeTimer: NodeJS.Timeout | null = null;
    let activeLi: HTMLLIElement | null = null;

    export function initialize(): void {
        $("ul#menu").removeClass("hover-dropdown");

        // root elemeken ne kövessük a href="#"-et
        $("ul#menu > li > a").on("click", function (this: HTMLLinkElement, evt: JQueryEventObject): void {
            if (this.getAttribute("href") === "#") {
                evt.preventDefault();
            }
        });

        // mobil menü kinyitás és becsukás
        $("#page > header .open-page-menu").on("click", function (this: HTMLButtonElement, evt: JQueryEventObject): void {
            $("html").addClass("mobile-menu-open");
            $("button.close-page-menu").focus();
        });
        $("#page > nav .close-page-menu").on("click", function (this: HTMLButtonElement, evt: JQueryEventObject): void {
            $("html").removeClass("mobile-menu-open");
            $("button.open-page-menu").focus();
        });

        attachCompactMenuWatcher(COMPACTMENU_MEDIA_QUERY);
        attachEvents();
    }

    function attachCompactMenuWatcher(mediaQuery: string): void {
        const matcher = window.matchMedia(mediaQuery);
        isCompact = matcher.matches;
        matcher.addListener((media) => {
            isCompact = media.matches;
        });
    }

    function menuOpen(li: HTMLLIElement | null): void {
        menuClearOpenDelayed();
        menuClearCloseDelayed();
        // click után rajta maradna a :focus
        if (li) {
            $(li).children("a").get(0).blur();
        } else if (activeLi) {
            $(activeLi).children("a").get(0).blur();
        }

        if (activeLi) {
            $(activeLi).removeClass("active");
        }
        activeLi = li;
        if (li) {
            $(li).addClass("active");
        }
    }

    function menuOpenDelayed(li: HTMLLIElement): void {
        menuClearOpenDelayed();
        menuClearCloseDelayed();
        openTimer = setTimeout(() => { menuOpen(li); }, MENU_OPEN_DELAY);
    }

    function menuCloseDelayed(): void {
        menuClearOpenDelayed();
        menuClearCloseDelayed();
        closeTimer = setTimeout(() => { menuOpen(null); }, MENU_CLOSE_DELAY);
    }

    function menuClearOpenDelayed(): void {
        if (openTimer) {
            clearTimeout(openTimer);
        }
    }

    function menuClearCloseDelayed(): void {
        if (closeTimer) {
            clearTimeout(closeTimer);
        }
    }

    function attachEvents(): void {
        // window resize handler
        let resizer: NodeJS.Timeout | undefined;
        $(window).on("resize", () => {
            if (resizer) {
                clearTimeout(resizer);
            } else {
                $("#page > nav").addClass("resizing");
            }
            resizer = setTimeout(() => {
                resizer = void 0;
                $("#page > nav").removeClass("resizing");
            }, 250);
        });

        // mouse move @ desktop
        $(document).on("mouseenter", "ul#menu > li", (event: JQueryEventObject) => {
            if (!isCompact) {
                if (activeLi) {
                    menuOpen(<HTMLLIElement>event.currentTarget);
                } else {
                    menuOpenDelayed(<HTMLLIElement>event.currentTarget);
                }
            }
        });
        $("ul#menu").on("mouseleave", () => {
            if (!isCompact) {
                menuCloseDelayed();
            }
        });

        // click + keyboard @ mobile + desktop
        $(document).on("click", "ul#menu > li > a", (event: JQueryEventObject) => {
            const targetParent = $(event.currentTarget).parent().get(0);
            // csak kis méretben, ha az aktív <A> elemre kattolunk
            if (isCompact && activeLi === targetParent) {
                menuOpen(null);
            } else {
                menuOpen(targetParent);
            }
        });
        $(document).on("keyup", "ul#menu > li > a", (event: JQueryEventObject) => {
            if (event.keyCode === KEYCODE_ENTER) {
                const targetParent = $(event.currentTarget).parent().get(0);
                // csak kis méretben ha az aktív <A> elemre ENTER-t nyomunk
                if (isCompact && activeLi === targetParent) {
                    menuOpen(null);
                } else {
                    menuOpen(targetParent);
                }
            }
        });
        $(document).on("keyup", (event: JQueryEventObject) => {
            if (event.keyCode === KEYCODE_ESC) {
                menuOpen(null);
            }
        });
    }
}

export default Menu;
