import jQuery from "jquery";
import svg4everybody from "svg4everybody";
import Menu from "./base/menu";

declare global {
    interface Window {
        jQuery: JQueryStatic;
        $: JQueryStatic;
    }
}
window.jQuery = jQuery;
window.$ = jQuery;

window.addEventListener('DOMContentLoaded', (event) => {
    document.body.addEventListener("mousedown", function () {
        if (!document.body.classList.contains("disable-focus-ring")) {
            document.body.classList.add("disable-focus-ring");
        }
    }, { passive: true });

    document.body.addEventListener("keydown", function () {
        if (document.body.classList.contains("disable-focus-ring")) {
            document.body.classList.remove("disable-focus-ring");
        }
    }, { passive: true });

    svg4everybody();
    Menu.initialize();

    // easter egg
    //require("./base/gray");

});
